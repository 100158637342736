<template>
  <li>
    <item-card>    
      <template v-slot:content>
          <item-text title="ID" :text="item.id" />

          <template v-if="item.correctable_type === 'App\\CatalogItem'">
            <item-text title="Preces kods" :text="item.correctable.sku" />
          </template>

          <template v-if="item.correctable_type === 'App\\InventoryItem'">
              <item-text title="Inventāra nr." :text="item.correctable.uuid" />
          </template>
          
          <item-text title="Nosaukums" :text="item.correctable.name" />
          <item-text title="Noliktava" :text="item.warehouse.name" />
          <item-text title="Mainītais daudzums" :text="item.quantity" />
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleStockCorrectionItemDetails",
  components: {
    ItemCard,
    ItemText,
  },
  props: ["item"],
}
</script>

<style>

</style>